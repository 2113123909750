import React, { useCallback, useEffect, useState } from 'react'
import { Stepper, InputAdornment, Icon, Grid } from '@material-ui/core'
import { ThermometerIcon, RulerIcon, MultipleCubesIcon, WcfIcon } from '../../icons'
import CubeTestConsistencyTestStep from './CubeTestConsistencyTestStep'
import CubeTestWcfStep from './CubeTestWcfStep'
import CubeTestCubesStep from './CubeTestCubesStep'
import CubeTestRemarksStep from './CubeTestRemarksStep'
import CubeTestDensityStep from './CubeTestDensityStep'
import CubeTestEditStep, { Step } from './CubeTestEditStep'
import CubeTestDetails from './CubeTestDetails'
import { ReceiptWithCubeTest } from '../../containers/Sampling'
import {getBinderTotal, getRecipeDensity} from "../../calculations/recipe";

const steps: Step[] = [
  {
    title: 'Temperatuur van het monster',
    label: 'Temperatuur',
    icon: <ThermometerIcon color="inherit" />,
    key: 'temperature',
    endAdornment: <InputAdornment position="end">&deg;C</InputAdornment>,
    decimalScale: 0,
    required: true
  },
  {
    title: 'Volumieke massa',
    icon: <Icon color="inherit">archive</Icon>,
    Component: CubeTestDensityStep,
    key: 'weight'
  },
  {
    title: 'Consistentiemetingen',
    icon: <RulerIcon color="inherit" />,
    Component: CubeTestConsistencyTestStep,
    key: 'consistencyTests'
  },
  {
    title: 'WCF invoeren',
    optional: true,
    key: 'trayWeightEmpty',
    icon: <WcfIcon color="inherit" />,
    Component: CubeTestWcfStep,
    isActive: ({ cubeTest, revision }) => {
      if (Boolean(cubeTest?.trayWeightEmpty) || Boolean(cubeTest?.trayWeightDry) || Boolean(cubeTest?.trayWeightWet)) {
        return true
      }
      const consistency = cubeTest?.consistencyTests && cubeTest?.consistencyTests.find(({ consistencyTestType }) => revision.recipe.consistencyClass.consistencyTestTypes.map(t => t.id).indexOf(consistencyTestType.id) >= 0)
      const value = consistency && consistency.values[0]
      if (value && value.value && revision.recipe.consistencyClass.maxRequirement && (value.value < revision.recipe.consistencyClass.minRequirement || value.value > revision.recipe.consistencyClass.maxRequirement)) {
        return true
      }
      return false
    }
  },
  {
    title: 'Luchtpercentage invoeren',
    label: 'Luchtpercentage',
    icon: <Icon color="inherit">cloud_upload</Icon>,
    key: 'airPercentage',
    endAdornment: <InputAdornment position="end">%</InputAdornment>,
    optional: true,
    isActive: ({ cubeTest, revision }) => {
      const diffWeight = cubeTest?.weight ? Math.abs( getRecipeDensity(revision.recipe.ingredients) - cubeTest?.weight / 3.375) : 0
      if (Boolean(cubeTest?.airPercentage) || (diffWeight > getRecipeDensity(revision.recipe.ingredients) * 0.03)) {
        return true
      }
      return false
    }
  },
  {
    title: 'Voorbereiden kubussen',
    icon: <MultipleCubesIcon color="inherit" />,
    Component: CubeTestCubesStep,
    key: 'cubes'
  },
  {
    title: 'Opmerkingen toevoegen',
    Component: CubeTestRemarksStep,
    key: 'remarks',
    icon: <Icon>comment</Icon>,
    optional: true,
    isActive: ({ cubeTest }) => {
      if (Boolean(cubeTest?.remarks)) {
        return true
      }
      return false
    }
  }
]

type CubeTestEditProps = {
  receipt: ReceiptWithCubeTest
  onChange?: (receipt: ReceiptWithCubeTest) => void
}

const CubeTestEdit: React.FC<CubeTestEditProps> = ({ onChange, ...props }) => {
  const [receipt, setReceipt] = useState(props.receipt)

  useEffect(() => {
    setReceipt(props.receipt)
    receipt.revision.recipe.density=getRecipeDensity(receipt.revision.recipe.ingredients)
    receipt.revision.recipe.binderTotal=getBinderTotal(receipt.revision.recipe.ingredients, Boolean(receipt.revision.recipe.attest))
  }, [props.receipt])

  const handleChange = useCallback((receipt: ReceiptWithCubeTest) => {
    setReceipt({ ...receipt })
    onChange && onChange(receipt)
  }, [setReceipt, onChange])

  return (
    <Grid item={true} style={{ flex: 1, height: '100%', overflow: 'auto' }}>
      <Stepper orientation="vertical" style={{ background: 'none' }}>
        {steps.map((step, k) => <CubeTestEditStep key={k} step={step} receipt={receipt} onChange={handleChange} />)}
      </Stepper>
      <CubeTestDetails receipt={receipt} />
    </Grid>
  )
}

export default CubeTestEdit
