import React, {Fragment, useMemo} from 'react'
import {
  Badge,
  Icon,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core'
import moment from 'moment'
import {Cube, CubeType} from '../../types'
import {CubeIcon} from '../../icons'
import {ReceiptWithCubeTestAndCubeWithCubeNumbers} from '../../containers/PressureStrength'

const useListItemStyles = makeStyles((theme: Theme) => ({
  listItemIcon: {
    marginRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.text.primary
  }
}));

type CubeListItemProps = {
  isSelected: boolean,
  cube: Cube & { cubeNumber: number },
  receipt?: ReceiptWithCubeTestAndCubeWithCubeNumbers,
  onSelect: () => void,
  onCancel: () => void
}

const CubeListItem: React.FC<CubeListItemProps> = ({ isSelected, cube, receipt, onSelect, onCancel, ...props }) => {
  const { listItemIcon } = useListItemStyles()
  const hideCube = !cube.cubeNumber || ((cube.pressureStrength || cube.penetration) && cube.weight);
  const errors = useMemo(() => {
    const errors: string[] = []
    if (receipt && cube.type === CubeType.Pressure && cube.pressureStrength && receipt.revision.recipe.strengthClass) {
      if (cube.pressureStrength < receipt.revision.recipe.strengthClass.cubePressureStrength - 4) {
        errors.push('De ingevoerde druksterkte is te laag')
      }
    }
    return errors
  }, [receipt, cube])

  return (
    <ListItem onClick={onSelect} button selected={isSelected} style={{display: hideCube ? 'none':'flex'}}>
      <div className={listItemIcon}>
        <Badge color="error" badgeContent={errors.length > 0 ?
          <Tooltip title={<div style={{ display: 'flex', flexDirection: 'column' }}>{errors.map((error, k) => <span key={k}>{error}</span>)}</div>}><span>{errors.length}</span></Tooltip> : null
        }><CubeIcon color="inherit" /></Badge>
        <Typography variant="subtitle2" style={{ whiteSpace: 'nowrap' }}>{cube.cubeNumber}</Typography>
      </div>
      <ListItemText
        primary={cube.type === CubeType.Pressure ? 'Druksterktemeting' : cube.type === CubeType.Penetration ? 'Indringingsmeting' : 'Rijpheidsmeting'}
        secondary={<Fragment>
          {cube.numberOfDays + (cube.numberOfDays === 1 ? ' dag' : ' dagen')}
          {moment(cube.testDate).format('YYYYMMDD') < moment().format('YYYYMMDD') && (<Fragment>,
            <Typography variant="caption" color="error"> {moment(cube.testDate).fromNow(true)} te laat</Typography>
          </Fragment>)}
        </Fragment>}
      />
      {(cube.type === CubeType.Pressure ? !cube.pressureStrength : !cube.penetration) && moment(cube.testDate).format('YYYYMMDD') < moment().format('YYYYMMDD') && (
        <ListItemSecondaryAction style={{display: hideCube ? 'none':'flex'}}>
          <IconButton onClick={onCancel} title="Druksterktemeting annuleren">
            <Icon>delete</Icon>
          </IconButton>
        </ListItemSecondaryAction>
      )}
      {!(cube.type === CubeType.Pressure ? !cube.pressureStrength : cube.type === CubeType.Penetration ? !cube.penetration : (!cube.pressureStrength || !cube.temperature)) && <ListItemSecondaryAction style={{display: hideCube ? 'none':'flex'}}><Icon color="secondary">check</Icon></ListItemSecondaryAction>}
    </ListItem>
  )
}

export default CubeListItem
