import { sanitizeCube } from '.'
import {Cube, CubeTest} from '../types'

export default function (cubeTest: CubeTest) {
  const { cubeNumber, cubes, ...data } = cubeTest

  for(let cube of cubes)
    if(cube.cubeNumber===undefined)
      cube.cubeNumber=calcFreeNumber(cubes)

  function calcFreeNumber(cubes: Cube[]): number {
    let freeNumber=1;
    for(let cube of cubes)
      if(cube.cubeNumber!==undefined && cube.cubeNumber===freeNumber) {
        freeNumber++
      }
    return freeNumber;
  }

  return { ...data, cubes: cubes.map(cube => sanitizeCube(cube)) }
}
