import React, { useEffect, useState, Fragment, useCallback } from "react";
import { Cube } from "../../types";
import { InputAdornment, Typography, FormControlLabel, Switch, Collapse, Icon } from "@material-ui/core";
import NumberFormat from "react-number-format";
import NumericTextField from "../NumericTextField";
import { ReceiptWithCubeTest } from "../../containers/Sampling";

const densityNumberFormatProps = {
  thousandSeparator: '.',
  decimalSeparator: ',',
  decimalScale: 1,
  displayType: 'text' as 'text'
}

type CubeDensityStepProps = {
  cube: Cube
  receipt: ReceiptWithCubeTest
  onChange: (cube: Cube) => void
}

const CubeDensityStep: React.FC<CubeDensityStepProps> = ({ cube, receipt, onChange }) => {
  const { weight, length, width, height, numberOfDays } = cube
  const [showCubeSize, setShowCubeSize] = useState(false)
  const [density, setDensity] = useState(calcDensity(length, width, height, weight));

  function calcDensity(length: any, width: any, height: any, weight: any) {
    const divider = length || width || height ? ((length || 150) * (width || 150) * (height || 150)) / 1000000 : 3.375;
    return weight===null ? 0 : weight / divider;
  }

  useEffect(() => {
    (length || width || height) && setShowCubeSize(true)
  }, [length, width, height])

  const handleShowCubeSize = useCallback(() => {
    if (showCubeSize) {
      onChange({ ...cube, length: undefined, width: undefined, height: undefined });
    }
    setShowCubeSize(!showCubeSize);
  }, [showCubeSize, cube, onChange]);

  const handleChange = useCallback((key: keyof Cube, value: number) => {
    if(key==='weight')
      setDensity(calcDensity(length, width, height, value));
    onChange({ ...cube, [key]: value as any });
  }, [cube, length, width, height, onChange]);

  return (
    <Fragment>
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: 16 }}>
          <NumericTextField
            label="Onverharde kubusgewicht"
            value={typeof receipt.cubeTest.weight !== 'undefined' && receipt.cubeTest.weight !== null ? receipt.cubeTest.weight : ''}
            disabled={true}
            InputProps={{
              endAdornment: <InputAdornment position="end">g</InputAdornment>,
            }}
            helperText={<Typography variant="caption">Volumieke massa: {receipt.cubeTest.weight && <NumberFormat value={receipt.cubeTest.weight / 3.375} {...densityNumberFormatProps} />} kg/m<sup>3</sup></Typography>}
          />
        </div>
        <div>
          <NumericTextField
            label={'Gewicht kubus na ' + numberOfDays + ' dagen'}
            onChange={e => handleChange('weight', e.target.value as number)}
            value={typeof weight !== 'undefined' && weight !== null ? weight : ''}
            InputProps={{
              endAdornment: <InputAdornment position="end">g</InputAdornment>
            }}
            helperText={<Typography variant="caption" color="secondary">Berekende volumieke massa: <NumberFormat value={density} {...densityNumberFormatProps} /> kg/m<sup>3</sup></Typography>}
          />
        </div>
      </div>
      <div>
        <FormControlLabel
          control={
            <Switch checked={showCubeSize} onChange={handleShowCubeSize} />
          }
          label="De kubusmaat wijkt af"
        />
      </div>
      <Collapse in={showCubeSize}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <NumericTextField
            label="Lengte"
            onChange={e => handleChange('length', e.target.value as number)}
            value={typeof length !== 'undefined' && length !== null ? length : ''}
            InputProps={{
              endAdornment: <InputAdornment position="end">mm</InputAdornment>
            }}
          />
          <Icon>clear</Icon>
          <NumericTextField
            label="Breedte"
            onChange={e => handleChange('width', e.target.value as any)}
            value={typeof width !== 'undefined' && width !== null ? width : ''}
            InputProps={{
              endAdornment: <InputAdornment position="end">mm</InputAdornment>
            }}
          />
          <Icon>clear</Icon>
          <NumericTextField
            label="Hoogte"
            onChange={e => handleChange('height', e.target.value as any)}
            value={typeof height !== 'undefined' && height !== null ? height : ''}
            InputProps={{
              endAdornment: <InputAdornment position="end">mm</InputAdornment>
            }}
          />
        </div>
      </Collapse>
    </Fragment>
  )
}

export default CubeDensityStep;
