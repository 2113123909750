export const Settings ={
  environment: 'development', // 'development' or 'production'
  company: 'server_centraal',
default_plant_id:3,
  URLs: {
    server: 'https://mcg.backend.jbmsoftware.nl',
    client: '',
    planningApp: {  // Central Planning App
      root: 'https://mcg.jbmsoftware.nl/',
      recipeApp: {  // Embedded Recipe App
        root: 'https://mcg.backend.jbmsoftware.nl/recipe-app/',  // Recipe App Root URL
        loginSegment: 'post/login'
      }
    },
    recipeApp: 'https://mcg.receptuur.jbmsoftware.nl/',
  }
}
