import axiosHttpClient from "./axiosHttpClient";

export function insert(data: any) {
	return axiosHttpClient.post('/receipt/create', data);
}

export function update(data: any) {
  return axiosHttpClient.post('/receipt/update', data);
}

export function remove(plantId: number, id: number) {
	return axiosHttpClient.get('/receipt/remove/'+plantId.toString()+'/'+id.toString());
}

export function getAll(plantId: number, dateType: string='', startDate=null, endDate=null) {
	let data={ plant_id: plantId, dateType: dateType, startDate: startDate, endDate: endDate }
	return axiosHttpClient.post('/receipts', data);
}

export function getOne(plantId: number, id: number) {
	return axiosHttpClient.get('/receipt/one/'+plantId.toString()+'/'+id.toString());
}

export function getWithCubeNumber(plantId: number, cubeNumber: number) {
	return axiosHttpClient.get('/receipt/with-cubenumber/'+plantId.toString()+'/'+cubeNumber.toString());
}


