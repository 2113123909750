import React, { useCallback } from 'react'
import {TextField, Button} from "@material-ui/core";
import {Settings} from "../config/settings";

const LoginForm: React.FC = () => {
	const handleLogin = useCallback((event: any) => {
		const target = event.target;

		const username=target.username.value.trim();
		const password=target.password.value.trim();

		// Simple login user validation
		const regex = new RegExp('[a-zA-Z0-9]{4,75}$');
		if(!regex.test(username)) return;

		event.preventDefault();

		// Login on server
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', 'company': Settings.company },
			body: JSON.stringify({ username: username, password: password })
		};
		fetch(Settings.URLs.planningApp.recipeApp.root+Settings.URLs.planningApp.recipeApp.loginSegment, requestOptions)
			.then(response => response.json())
			.then(data => {
				if(!data.error) {
					// Valid login; store token
					localStorage.setItem('token', data.data.token);
				}
				// Redirect to home page
				const url=new URL(window.location as any);
				window.location.href=url.origin;
			});
	},[]);

	return (
		<form method="post" className="login" onSubmit={handleLogin}>
			<h4 className="MuiTypography-root MuiTypography-h4">Receptuur &amp; Lab-software</h4>
			<h5 className="MuiTypography-root MuiTypography-h6">Inloggen</h5>
			<TextField id="username" label="Gebruikersnaam" variant="outlined" required={true} />
			<TextField id="password" type="password" label="Wachtwoord" variant="outlined" required={true} />
			<Button variant="contained" type="submit">Inloggen</Button>
		</form>
	)
}

export default LoginForm;
