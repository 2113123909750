import {
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core'
import React, {Fragment, useCallback, useState} from 'react'
import { ReceiptWithCubeTest } from '../../containers/Sampling'
import moment from 'moment'
import { format } from 'react-numberinput-formatter'
import { CubeType } from '../../types'
import {getWcfAsString} from "../../calculations/recipe";

const columns = [
  { name: 'cubeTest.cubeNumber', title: 'Kubus', getValue: ({ cubeTest }: ReceiptWithCubeTest) => cubeTest.cubeNumber },
  { name: 'id', title: 'Bon', getValue: (receipt: ReceiptWithCubeTest) => receipt.id },
  { name: 'customer', title: 'Klant', getValue: (receipt: ReceiptWithCubeTest) => receipt.customer },
  { name: 'project', title: 'Werk', getValue: (receipt: ReceiptWithCubeTest) => receipt.project },
  { name: 'revision.recipe.recipeName', title: 'Recept', disableSort: true, getValue: ({ revision }: ReceiptWithCubeTest) => revision.recipe.recipeName },
  { name: 'cubeTest.sampleDate', title: 'Datum', getValue: ({ cubeTest }: ReceiptWithCubeTest) => moment(cubeTest.sampleDate).format('YYYY-MM-DD') },
  { name: 'temperature', title: 'Temp', numeric: true, disableSort: true, getValue: ({ cubeTest }: ReceiptWithCubeTest) => cubeTest.temperature ? format(cubeTest.temperature, { maximumFractionDigits: 1 }) + ' °C' : '' },
  { name: 'weight', title: 'Volumieke massa', numeric: true, disableSort: true, getValue: ({ cubeTest }: ReceiptWithCubeTest) => cubeTest.weight ? format((cubeTest.weight || 0) / 3.375, { maximumFractionDigits: 1 }) + ' kg/m3' : '' },
  { name: 'wcf', title: 'WCF', numeric: true, disableSort: true, getValue: ({ cubeTest, revision }: ReceiptWithCubeTest) =>
      cubeTest.trayWeightEmpty && cubeTest.trayWeightDry && cubeTest.trayWeightWet && cubeTest.weight &&
      (getWcfAsString(cubeTest, revision) || 'Nvt') },
  { name: 'consistencyTest', title: 'Consistentiemeting', disableSort: true, getValue: ({ cubeTest }: ReceiptWithCubeTest) => cubeTest.consistencyTests.map(c => `${c.consistencyTestType.description}: ${c.values.map(v => `${format(v.value || 0,{ maximumFractionDigits: 2 })} ${v.parameter.unit}`).join(', ')}`).join(', ')},
  { name: 'pressureStrength', title: 'Druksterkte', disableSort: true, getValue: ({ cubeTest }: ReceiptWithCubeTest) => cubeTest.cubes.filter(c => c.type === CubeType.Pressure).map(c => c.pressureStrength!==null ? (`${c.pressureStrength} N/mm2 (${c.numberOfDays} ${c.numberOfDays === 1 ? 'dag' : 'dagen'})`) : null).filter(s => s).join(', ') }
]

const All: React.FC<{ receipts: ReceiptWithCubeTest[] }> = ({ receipts }) => {
  const [orderBy, setOrderBy] = useState('cubeTest.cubeNumber')
  const [sortDirection, setSortDirection] = useState('asc' as 'desc' | 'asc')
  const [customerView, setCustomerView] = useState(false)

  const handleCustomerViewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerView(event.target.checked)
  };

  const handleSortChange = useCallback((columnName: string) => {
    if (columnName === orderBy) {
      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc')
    } else {
      setOrderBy(columnName)
      setSortDirection('desc')
    }
  }, [orderBy, sortDirection])

  const sortCubes = useCallback((a: ReceiptWithCubeTest, b: ReceiptWithCubeTest) => {
    const keys = orderBy.split('.')
    let valA: any = a
    let valB: any = b
    keys.forEach(key => {
      valA[key as keyof ReceiptWithCubeTest] ? (valA = valA[key as keyof ReceiptWithCubeTest]) : (valA = 0)
      valB[key as keyof ReceiptWithCubeTest] ? (valB = valB[key as keyof ReceiptWithCubeTest]) : (valB = 0)
    })
    if (orderBy === 'cubeTest.sampleDate') {
      valA = new Date(valA).getTime()
      valB = new Date(valB).getTime()
    }
    return sortDirection === 'desc' ? valB - valA : valA - valB
  }, [orderBy, sortDirection])

  const getValue = useCallback((cubeTest: ReceiptWithCubeTest, key: string) => {
    const keys = key.split('.')
    let val: any = cubeTest
    keys.forEach(key => val[key as keyof ReceiptWithCubeTest] ? val = val[key as keyof ReceiptWithCubeTest] : null)
    return val;
  }, [])

  function getMultiLineValues(columnName: string, value: any): string[] {
    let lines=[];
    if(columnName==='consistencyTest') {
      for(let consistencyTest of value.cubeTest.consistencyTests) {
        let line=consistencyTest.consistencyTestType.description;
        for(let value of consistencyTest.values) {
          line+=': '+format(value.value || 0,{ maximumFractionDigits: 2 }) + ' ' + value.parameter.unit;
        }
        lines.push(line);
      }
    }
    if(columnName==='pressureStrength') {
      for(let cube of value.cubeTest.cubes) {
        if(cube.pressureStrength===undefined || cube.pressureStrength===null || cube.type!==CubeType.Pressure)
          continue;
        lines.push(cube.pressureStrength + ' N/mm2 (' + cube.numberOfDays + ' ' + (cube.numberOfDays === 1 ? 'dag' : 'dagen')+')');
      }
    }
    return lines;
  }

  return (
    <Fragment>
      <div className={'hide-print'} style={{textAlign:'right',marginRight: '20px'}}>
        <FormControlLabel control={
          <Switch checked={customerView} onChange={handleCustomerViewChange}  />
        } label="Klantweergave" />
      </div>
    <Table className={customerView ? 'customerView' : ''}>
    <TableHead>
      <TableRow>
        {columns.map((column, k) => (
          <TableCell key={k}
            sortDirection={orderBy === column.name ? sortDirection : false}
            align={column.numeric ? 'right' : 'left'}
            style={{ whiteSpace: 'nowrap' }}
            className={column.name}
          >
            {!column.disableSort ? <TableSortLabel active={orderBy === column.name} direction={sortDirection} onClick={() => handleSortChange(column.name)}>
              {column.title}
            </TableSortLabel> : column.title}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
      {receipts.sort(sortCubes).map((receipt, k) => <TableRow key={k} hover={true}>
        {columns.map((column, c) => (

          column.name==='consistencyTest' || column.name==='pressureStrength' ?
          (
              <td id={c.toString()} className={'MuiTableCell-root MuiTableCell-body '+column.name}>
                {getMultiLineValues(column.name, getValue(receipt, column.name)).map( (value) => {
                  return <span style={{display: 'block'}}>{value}</span>
                })}
              </td>
          ) : (
              <TableCell key={c} align={column.numeric ? 'right' : 'left'} style={{ whiteSpace: 'nowrap' }} className={column.name}>
                {column.getValue ?
                  column.getValue(receipt) :
                  getValue(receipt, column.name)
                }
              </TableCell>
            )
        ))}
      </TableRow>)}
    </TableBody>
    </Table>
    </Fragment>
  )
}

export default All
