import React, {Fragment, useCallback, ChangeEvent, useMemo} from 'react'
import { InputAdornment, Typography, Box } from '@material-ui/core'
import NumericTextField from '../NumericTextField'
import { Alert } from '@material-ui/lab'
import { format } from 'react-numberinput-formatter'
import useAuthorized from '../../useAuthorized'
import { ReceiptWithCubeTest } from '../../containers/Sampling'
import {getRecipeDensity} from "../../calculations/recipe";

const CubeTestDensityStep: React.FC<{ receipt: ReceiptWithCubeTest, onChange: (receipt: ReceiptWithCubeTest) => void }> = ({ receipt, onChange }) => {
  const canEdit = useAuthorized(['update:cubetests'])
  const density = useMemo(() => (receipt.cubeTest.weight || 0) / 3.375, [receipt.cubeTest.weight])

  const handleChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    receipt.cubeTest.weight = Number(e.target.value)
    onChange({ ...receipt, cubeTest: { ...receipt.cubeTest } })
  }, [receipt, onChange])

  return (
    <Fragment>
      <Box display="flex" marginBottom={2}>
        <Alert variant="outlined" severity="info" style={{ marginRight: 8 }}>
          <Typography variant="body2">Berekende volumieke massa: {format(density, { maximumFractionDigits: 1 })} kg/m<sup>3</sup></Typography>
        </Alert>
        <Alert variant="outlined" severity="info">
          <Typography variant="body2">Volumieke massa recept: {format(getRecipeDensity(receipt.revision.recipe.ingredients),{ maximumFractionDigits: 1 })} kg/m<sup>3</sup></Typography>
        </Alert>
      </Box>
      <NumericTextField
        label="Gewicht kubus"
        onChange={handleChange as any}
        value={receipt.cubeTest?.weight || ''}
        required={true}
        error={!receipt.cubeTest?.weight}
        InputProps={{
          endAdornment: <InputAdornment position="end">g</InputAdornment>,
        }}
        disabled={!canEdit}
      />
    </Fragment>
  )
}

export default CubeTestDensityStep
