import React, {useState, useCallback, useRef, useMemo, Fragment, useEffect} from 'react'
import { Card, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Icon, Button, CardContent, MenuItem, InputAdornment, Select, Typography, Popover } from '@material-ui/core'
import {ConsistencyTestType, ConsistencyTest, ConsistencyTestValue} from '../../types'
import { ConfirmDialog } from '..'
import NumericTextField from '../NumericTextField'
import { format } from 'react-numberinput-formatter'
import useAuthorized from '../../useAuthorized'
import { ReceiptWithCubeTest } from '../../containers/Sampling'
import {getAll as getConsistencyTestTypes} from '../../HTTPClients/RecipeApp/recipes/consistency-test-types';

type ConsistencyTestValueCellProps = {
  consistencyTest: ConsistencyTest
  error?: boolean
  onChange: (consistencyTest: ConsistencyTest) => void
}

const ConsistencyTestValueCell: React.FC<ConsistencyTestValueCellProps> = props => {
  const [editAnchorEl, setEditMode] = useState(null as HTMLElement | null)
  const inputRef = useRef(null)
  const { consistencyTest, error, onChange } = props
  const hasValues = consistencyTest.values.reduce((hasValues, current) => Boolean(current.value) && (hasValues = true), false)
  const canEdit = useAuthorized(['update:cubetests'])

  const handleChange = useCallback((cubeTestValue: ConsistencyTestValue, value: number) => {
    cubeTestValue.value = value
    const { values } = consistencyTest
    const index = values.findIndex(v => v === cubeTestValue)
    values[index] = { ...cubeTestValue }
    consistencyTest.values = [...values]
    onChange(consistencyTest)
  }, [consistencyTest, onChange])

  return (
    <TableCell size="small" onClick={(e: any) => canEdit && !Boolean(editAnchorEl) && setEditMode(e.currentTarget)} style={{ cursor: 'pointer' }}>
      <Popover
        open={Boolean(editAnchorEl)}
        PaperProps={{ square: true }}
        anchorEl={editAnchorEl}
        transformOrigin={{ vertical: 0, horizontal: -24 }}
        onClose={() => setEditMode(null)}
        onEntered={(ref: HTMLElement) => {
          if (inputRef.current) {
            ref.removeAttribute('tabindex');
            (inputRef.current as unknown as HTMLInputElement).focus()
          }
        }}
      >
        <div style={{ padding: '8px 24px', display: 'flex', flexDirection: 'column' }}>
          {consistencyTest.values.map((cubeTestValue, k) => (
            <NumericTextField
              key={k}
              inputRef={k === 0 ? inputRef : undefined}
              {...consistencyTest.values.length > 1 && { label: cubeTestValue.parameter.label }}
              placeholder={cubeTestValue.parameter.placeholder || cubeTestValue.parameter.label}
              value={typeof cubeTestValue.value !== 'undefined' && cubeTestValue.value !== null ? cubeTestValue.value : ''}
              onChange={(e) => handleChange(cubeTestValue, e.target.value as number)}
              maximumFractionDigits={2}
              disabled={!canEdit}
              InputProps={{
                endAdornment: <InputAdornment position="end">{cubeTestValue.parameter.unit}</InputAdornment>
              }}
            />
          ))}
        </div>
      </Popover>
      {hasValues ? <Typography variant="subtitle2" {...error && { color: 'error' }}>{consistencyTest.values.map(item => (item.value ? format(item.value, { maximumFractionDigits: 2 }) : '') + (item.parameter.unit ? ' (' + item.parameter.unit + ')' : '')).join(', ')}&nbsp;&nbsp;&nbsp;<Icon fontSize="small" style={{ marginBottom: -5 }}>create</Icon></Typography> : <Typography variant="subtitle2" {...error && { color: 'error' }} style={{ opacity: error ? 1 : 0.7 }}>Metingwaarde opgeven</Typography>}
    </TableCell>
  )
}

type ConsistencyTestRowProps = {
  consistencyTest: ConsistencyTest
  consistencyTestTypes: ConsistencyTestType[]
  receipt: ReceiptWithCubeTest
  error?: boolean
  disableDelete?: boolean
  onDelete: (consistencyTest: ConsistencyTest) => void
  onChange: (ConsistencyTest: ConsistencyTest) => void
}

const ConsistencyTestRow: React.FC<ConsistencyTestRowProps> = ({ consistencyTest, consistencyTestTypes, receipt, disableDelete, onDelete, onChange }) => {
  const canEdit = useAuthorized(['update:cubetests'])

  const handleConsistencyTestTypeChange = useCallback((consistencyTestId: number) => {
    const consistencyTestType = consistencyTestTypes.find(item => item.id === consistencyTestId)
    if (consistencyTestType) {
      consistencyTest.consistencyTestType = consistencyTestType
      consistencyTest.values = consistencyTestType.parameters.map((parameter: {}) => ({ parameter, value: undefined } as unknown as ConsistencyTestValue))
    }
    onChange(consistencyTest)
  }, [consistencyTest, consistencyTestTypes, onChange])

  const hasError = useMemo(() => {
    const { consistencyTestTypes, minRequirement, maxRequirement } = receipt.revision.recipe.consistencyClass
    const [value] = consistencyTest.values
    const matchTest = consistencyTestTypes.find(c => c.id === consistencyTest.consistencyTestType.id)
    const noReqs = !minRequirement && !maxRequirement
    return Boolean(matchTest && !noReqs ? (!value || !value.value || (minRequirement && value.value < minRequirement) || (maxRequirement && value.value > maxRequirement)) : false)
  }, [consistencyTest.values, consistencyTest.consistencyTestType, receipt.revision.recipe.consistencyClass])

  return (
    <TableRow>
      <TableCell size="small">
        <Select
          value={consistencyTest.consistencyTestType.id}
          onChange={(e) => handleConsistencyTestTypeChange(Number(e.target.value))}
          disableUnderline={true}
          disabled={!canEdit || disableDelete}
        >
          {consistencyTestTypes.map((consistencyTestType, k) => (
            <MenuItem key={k} value={consistencyTestType.id}>{consistencyTestType.description}</MenuItem>
          ))}
        </Select>
      </TableCell>
      <ConsistencyTestValueCell
        consistencyTest={consistencyTest}
        onChange={onChange}
        error={hasError}
      />
      <TableCell size="small">
        {receipt.revision.recipe.consistencyClass.consistencyTestTypes.findIndex(c => c.id === consistencyTest.consistencyTestType.id) >= 0 && receipt.revision.recipe.consistencyClass.minRequirement}
      </TableCell>
      <TableCell size="small">
        {receipt.revision.recipe.consistencyClass.consistencyTestTypes.findIndex(c => c.id === consistencyTest.consistencyTestType.id) >= 0 && receipt.revision.recipe.consistencyClass.maxRequirement}
      </TableCell>
      <TableCell size="small" style={{ width: 1 }}>
        {!disableDelete && canEdit && <IconButton onClick={() => onDelete(consistencyTest)}>
          <Icon>delete</Icon>
        </IconButton>}
      </TableCell>
    </TableRow>
  )
}

const ConsistencyTestsStep: React.FC<{ receipt: ReceiptWithCubeTest, onChange: (receipt: ReceiptWithCubeTest) => void }> = ({ receipt, onChange }) => {
//  const { data } = useQuery<{ consistencyTestTypes: ConsistencyTestType[] }>(CONSISTENCY_TEST_TYPES, { fetchPolicy: 'cache-first' })
  const [consistencyTestTypes, setConsistencyTestTypes]=useState([] as ConsistencyTestType[]);

//  const consistencyTestTypes = useMemo(() => data?.consistencyTestTypes || [], [data])
  const error = useMemo(() => {
    return (receipt.cubeTest?.consistencyTests.filter(test => receipt.revision.recipe.consistencyClass.consistencyTestTypes.findIndex(c => c.id === test.consistencyTestType.id) >= 0 && test.values.filter(value => value.value && value.value > 0).length > 0) || []).length === 0
  }, [receipt.cubeTest, receipt.revision.recipe.consistencyClass.consistencyTestTypes])
  const testsForClass = useMemo(() => {
    return receipt.cubeTest?.consistencyTests.filter(test => receipt.revision.recipe.consistencyClass.consistencyTestTypes.findIndex(c => c.id === test.consistencyTestType.id) >= 0) || []
  }, [receipt.cubeTest, receipt.revision.recipe.consistencyClass.consistencyTestTypes])
  const [confirmProps, confirmDelete] = useState({ open: false } as { open: boolean, onCancel?: () => void, onConfirm?: () => void })
  const canEdit = useAuthorized(['update:cubetests'])

  useEffect(() => {
    if(!consistencyTestTypes.length) {
      getConsistencyTestTypes().then(
          function(response: any) {
            setConsistencyTestTypes(response.data.data);
          }
      )
    }
  },[consistencyTestTypes, setConsistencyTestTypes])

  const handleChange = useCallback(async (consistencyTest: ConsistencyTest) => {
    const consistencyTests = receipt.cubeTest.consistencyTests || []
    const index = consistencyTests.findIndex(ct => ct === consistencyTest)
    index >= 0 && (consistencyTests[index] = consistencyTest)
    onChange({ ...receipt, cubeTest: { ...receipt.cubeTest, consistencyTests: [...consistencyTests] } })
  }, [receipt, onChange])

  const handleAdd = useCallback(async () => {
    const test: ConsistencyTest = {
      consistencyTestType: consistencyTestTypes[0],
      values: consistencyTestTypes[0].parameters.map(parameter => ({ parameter, value: undefined })),
    }
    onChange({ ...receipt, cubeTest: { ...receipt.cubeTest, consistencyTests: [...receipt.cubeTest.consistencyTests, test] } })
  }, [receipt, consistencyTestTypes, onChange])

  const handleDelete = useCallback((consistencyTest: ConsistencyTest) => {
    confirmDelete({
      open: true,
      onCancel: () => confirmDelete({ open: false }),
      onConfirm: async () => {
        const index = receipt.cubeTest.consistencyTests.findIndex(test => test === consistencyTest)
        index >= 0 && receipt.cubeTest.consistencyTests.splice(index, 1)
        onChange({ ...receipt, cubeTest: { ...receipt.cubeTest, consistencyTests: [...receipt.cubeTest.consistencyTests] } })
        confirmDelete({ open: false })
      }
    })
  }, [receipt, onChange])

  return (
    <Fragment>
      <Card>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Type meting</TableCell>
              <TableCell>Meting waarde</TableCell>
              <TableCell>Min.</TableCell>
              <TableCell colSpan={2}>Max.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {receipt.cubeTest?.consistencyTests.map((consistencyTest, k) => {
              return (
                <ConsistencyTestRow
                  key={k}
                  consistencyTest={consistencyTest}
                  consistencyTestTypes={consistencyTestTypes}
                  receipt={receipt}
                  error={error}
                  disableDelete={testsForClass.length >= 1 && receipt.revision.recipe.consistencyClass.consistencyTestTypes.findIndex(c => c.id === consistencyTest.consistencyTestType.id) >= 0}
                  onDelete={handleDelete}
                  onChange={handleChange}
                />
              )
            })}
          </TableBody>
        </Table>
        {canEdit && <CardContent>
          <Button color="primary" variant="contained" onClick={handleAdd}>Meting toevoegen</Button>
        </CardContent>}
      </Card>
      <ConfirmDialog
        title="Consistentiemeting verwijderen"
        content="Weet u zeker dat u deze consistentiemeting wilt verwijderen?"
        confirmText="Verwijderen"
        {...confirmProps}
      />
    </Fragment>
  )
}

export default ConsistencyTestsStep
