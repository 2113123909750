import React, { useState, Fragment } from 'react'
import { makeStyles, Theme, Avatar, Icon, IconButton, Drawer, Card, CardHeader, Table, TableBody, TableRow, TableCell, Link } from '@material-ui/core'
import { Receipt } from '../../types'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import { format } from 'react-numberinput-formatter'
import {getBinderTotal, getPercentageFine, getRecipeDensity} from "../../calculations/recipe";

function CustomNumberFormat(props: NumberFormatProps) {
  return <NumberFormat
    {...props}
    displayType="text"
    decimalSeparator=","
    thousandSeparator="."
  />
}

const useCubeTestDetailstyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: 1000,
  },
  drawerPaper: {
    maxWidth: 480,
    boxSizing: 'border-box',
    paddingTop: 64,
    zIndex: 1000
  },
  toggleButton: {
    position: 'absolute',
    top: 72,
    right: 16
  }
}))

type Info = {
  avatar: JSX.Element,
  subheader: string,
  details: Array<{ title: string, value: (receipt: Receipt) => JSX.Element | string }>
}

const info: Info[] = [
  {
    avatar: <Avatar><Icon color="inherit">assignment</Icon></Avatar>,
    subheader: "Order details",
    details: [
      { title: 'Ordernummer', value: ({ id }) => id + '' },
      { title: 'Klant', value: ({ customer }) => customer + '' },
      { title: 'Werk', value: ({ project }) => project + '' },
      { title: "Aantal kuub", value: ({ amount }) => <span><CustomNumberFormat value={amount} /> m<sup>3</sup></span> }
    ]
  },
  {
    avatar: <Avatar><Icon color="inherit">assignment</Icon></Avatar>,
    subheader: 'Recept',
    details: [
      { title: "Receptnummer", value: ({ revision }) => <span><Link color={"secondary"} style={{ display: 'flex', whiteSpace: 'nowrap', alignItems: 'center', cursor: 'pointer', textDecoration: 'none' }} href={`${window.location.origin}/receptuur/recipe/${revision.recipe.id}`} target="_BLANK">{revision.recipe.id}&nbsp;&nbsp;<Icon>screen_share</Icon></Link></span> },
      { title: "Receptnaam", value: ({ revision }) => revision.recipe.recipeName },
      { title: "WBF", value: ({ revision }) => <span>{format(revision.recipe.wbf, { minimumFractionDigits: 3 })}</span> },
      { title: "Volumieke massa", value: ({ revision }) => <span>{format(getRecipeDensity(revision.recipe.ingredients),{ maximumFractionDigits: 1, useGrouping: true })} kg/m<sup>3</sup></span> },
      { title: "Hoeveelheid bindmiddel", value: ({ revision }) => <span>{format(getBinderTotal(revision.recipe.ingredients, Boolean(revision.recipe.attest)),{ maximumFractionDigits: 2 })} kg</span> },
      { title: "Fijnmateriaal", value: ({ revision }) => <span>{format(getPercentageFine(revision.recipe.ingredients),{ maximumFractionDigits: 1 })} L</span> },
      { title: "Luchtgehalte", value: ({ revision }) => <span>{format(revision.recipe.airPercentage, { maximumFractionDigits: 2 })}%</span> }
    ]
  }
]

const CubeTestDetails: React.FC<{ receipt: Receipt }> = ({ receipt }) => {
  const [open, setOpen] = useState(false)
  const { root, drawerPaper, toggleButton } = useCubeTestDetailstyles()

  return (
    <Fragment>
      <div className={toggleButton}>
        <IconButton onClick={() => setOpen(true)}>
          <Icon>info</Icon>
        </IconButton>
      </div>
      <Drawer variant="persistent" open={open} anchor="right" className={root} classes={{ paper: drawerPaper }}>
        <IconButton onClick={() => setOpen(false)} className={toggleButton}>
          <Icon>close</Icon>
        </IconButton>
        <Card style={{ height: '100%', overflow: 'auto' }}>
          {info.map(({ avatar, subheader, details }, k) => (
            <Fragment key={k}>
              <CardHeader avatar={avatar} subheader={subheader} />
              <Table style={{ marginBottom: 16 }}>
                <TableBody>
                  {details.map(({ title, value }, i) => <TableRow key={i}>
                    <TableCell>{title}:</TableCell>
                    <TableCell>{value(receipt)}</TableCell>
                  </TableRow>)}
                </TableBody>
              </Table>
            </Fragment>
          ))}
        </Card>
      </Drawer>
    </Fragment>
  )
}

export default CubeTestDetails
