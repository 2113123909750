import { Checkbox, Collapse, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Typography } from '@material-ui/core'
import React, { Fragment, useState } from 'react'

const useStyles = makeStyles({
  smallCheckbox: {
    padding: '0',
    marginRight: 9
  }
})

interface FilterProps<T extends { id: number | string, title: string, count: number }> {
  title: string,
  items: T[],
  selected: T[],
  reportType?: string
  toggleSelected: (selected: T) => void
  unselectAll: () => void
}

function Filter<T extends { id: number | string, title: string, count: number }>({ title, items, selected, reportType, toggleSelected, unselectAll }: FilterProps<T>) {
  const [showMore, setShowMore] = useState(false)
  const { smallCheckbox } = useStyles()
  return <List dense={true}>
    <ListItem>
      <ListItemText primary={title} />
      {selected.length > 0 && <ListItemSecondaryAction><Typography color="secondary" variant="caption" onClick={unselectAll} style={{ cursor: 'pointer' }}>Alles wissen</Typography></ListItemSecondaryAction>}
    </ListItem>
    {items.slice(0, 5).map(item => <ListItem key={item.id} button={true} onClick={() => toggleSelected(item)}>
      <Checkbox className={smallCheckbox} checked={selected.findIndex(s => s.id === item.id) >= 0} />
      <ListItemText primaryTypographyProps={{ style: { whiteSpace: 'nowrap' } }} primary={<Fragment><Typography variant="body2" display="inline">{item.title}</Typography> {reportType!=='en206' && <Typography variant="caption" display="inline">({item.count} bonnen)</Typography>}</Fragment>} />
    </ListItem>)}
    <Collapse in={showMore}>
      {items.slice(5).map(item => <ListItem key={item.id} button={true} onClick={() => toggleSelected(item)}>
        <Checkbox className={smallCheckbox} checked={selected.findIndex(s => s.id === item.id) >= 0} />
        <ListItemText primaryTypographyProps={{ style: { whiteSpace: 'nowrap' } }} primary={<Fragment><Typography variant="body2" display="inline">{item.title}</Typography> {reportType!=='en206' && <Typography variant="caption" display="inline">({item.count} bonnen)</Typography>}</Fragment>} />
      </ListItem>)}
    </Collapse>
    {items.length > 5 && <ListItem button={true} onClick={() => setShowMore(!showMore)}>
      <ListItemText secondary={showMore ? `minder tonen` : `${items.length - 5} meer tonen...`} />
    </ListItem>}
  </List>
}

export default Filter
