import React, { Fragment, useMemo } from 'react'
import moment from 'moment'
import { Icon, makeStyles, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography } from '@material-ui/core'
import { Receipt, StrengthClass } from '../../types'
import { format } from 'react-numberinput-formatter'

const useStyles = makeStyles(theme => ({
  badge: {
    display: 'inline-block',
    boxSizing: 'border-box',
    width: 24,
    height: 24,
    padding: '3px 0',
    margin: '-4px 0',
    borderRadius: 12,
    background: theme.palette.primary.main,
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.primary.contrastText
  }
}))

const Month: React.FC<{ receipts: Receipt[], startDate: Date, endDate: Date }> = ({ receipts, startDate, endDate }) => {
  const { badge } = useStyles()
  const days = useMemo(() => new Array(Math.abs(moment(startDate).diff(endDate, 'days')) + 1).fill(null).map((v, k) => moment(startDate).add(k, 'days').toDate()), [startDate, endDate])
  const samplesByStrengthClass = useMemo(() => {
    return receipts.reduce((arr: Array<{ strengthClass?: StrengthClass, days: Array<{ date: Date, order_amount: number, samples: number }> }>, { revision, order_amount, cubeTest, ...receipt }) => {
      const index = arr.findIndex(({ strengthClass }) => (!revision.recipe.strengthClass && !strengthClass) || strengthClass?.id === revision.recipe.strengthClass?.id)
      if (index >= 0) {
        const dateIndex = arr[index].days.findIndex(({ date }) => moment(date).isSame(receipt.date, 'day'))
        if (dateIndex >= 0) {
          arr[index].days[dateIndex].order_amount += order_amount
          cubeTest && (arr[index].days[dateIndex].samples += 1)
        } else {
          arr[index].days.push({ date: receipt.date, order_amount, samples: cubeTest ? 1 : 0 })
        }
      } else {
        if (!revision.recipe.strengthClass) {
          console.log(revision.recipe.strengthClass)
        }
        arr.push({ strengthClass: revision.recipe.strengthClass || undefined, days: [{ date: receipt.date, order_amount, samples: cubeTest ? 1 : 0 }] })
      }
      return arr
    }, []).sort((a, b) => (a.strengthClass?.cubePressureStrength || 0) - (b.strengthClass?.cubePressureStrength || 0))
  }, [receipts])
  return <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell />
        {samplesByStrengthClass.map(({ strengthClass }, k) => <TableCell key={k} align="right" colSpan={3}>{strengthClass?.code || 'LOS'}</TableCell>)}
        <TableCell />
      </TableRow>
      <TableRow>
        <TableCell />
        {samplesByStrengthClass.map((v, k) => <TableCell key={k} align="right" colSpan={3}><Typography variant="caption">m3 - kb</Typography></TableCell>)}
        <TableCell />
      </TableRow>
    </TableHead>
    <TableBody>
      {days.map((date, k) => <Fragment key={k}>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>{moment(date).format('YYYY-MM-DD')}</TableCell>
          {samplesByStrengthClass.map(({ strengthClass, days }, l) => {
            const match = days.find(day => moment(day.date).isSame(date, 'day'))
            const hasError = match && strengthClass && ((match.samples < match.order_amount / strengthClass.samplePerVolume && match.samples < strengthClass.sampleMaximum) || match.samples < strengthClass.sampleMinimum) ? true : false
            return <Fragment key={l}><TableCell key={strengthClass?.id || 'los'} align="right" style={{ whiteSpace: 'nowrap', paddingRight: 4 }}>
              {match ? format(match.order_amount,{ maximumFractionDigits: 2, minimumFractionDigits: 2 }) : ''}
            </TableCell>
              <TableCell style={{ width: 1, padding: 0 }} className="print-nowidth" align="center">
                {match && match.samples > 0 ? <span className={badge}>{match.samples}</span> : match ? '-' : ''}
              </TableCell>
              <TableCell style={{ width: 1, padding: 0 }} className="print-nowidth">
                {hasError ? <Icon color="error" style={{ margin: '-8px 0' }}>warning</Icon> : ''}
              </TableCell>
            </Fragment>
          })}
          <TableCell />
        </TableRow>
        {moment(date).weekday() === 6 ? <TableRow><TableCell style={{ height: 8 }} colSpan={samplesByStrengthClass.length * 3 + 2} /></TableRow> : null}
      </Fragment>)}
    </TableBody>
    <TableFooter>
      <TableRow>
        <TableCell>Totaal</TableCell>
        {samplesByStrengthClass.map(({ days }, k) => <Fragment key={k}><TableCell align="right" style={{ paddingRight: 4 }}>{format(days.reduce((total, { order_amount }) => total += order_amount, 0), { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell><TableCell colSpan={2} /></Fragment>)}
        <TableCell />
      </TableRow>
    </TableFooter>
  </Table>
}

export default Month
