import React, {Fragment, useMemo} from 'react'
import {Cube, CubesChunckProperties, CubeTest, ResourceType} from '../../types'
import { FamilyWithReceipts } from './EN206'
import * as math from 'mathjs'
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from '@material-ui/core'
import NumberFormat from 'react-number-format'
import moment from 'moment'
import {Link} from 'react-router-dom'

const numberFormatProps = {
  thousandSeparator: '.',
  decimalSeparator: ',',
  displayType: 'text' as 'text'
}

function sortCubeTests(a: CubeTest, b: CubeTest) {
  const aDate = a.sampleDate ? new Date(a.sampleDate) : 0
  const bDate = b.sampleDate ? new Date(b.sampleDate) : 0
  return (aDate > bDate && 1) || (aDate < bDate && -1) || 0
}

const ReportPressureStrength: React.FC<{ family: FamilyWithReceipts, startDate: Date, endDate: Date }> = ({ family, startDate, endDate }) => {
  const sorted = useMemo(() =>
    family.receipts.map(receipt => receipt.cubeTest).sort(sortCubeTests),[family.receipts])

  let characteristicStrength = 0
    //this SHOULD always be available but handling checks anyway
  if (family.receipts.length > 0 &&
    family.receipts[0].revision &&
    family.receipts[0].revision.recipe &&
    family.receipts[0].revision.recipe.strengthClass){
    characteristicStrength = family.receipts[0].revision.recipe.strengthClass.cubePressureStrength
  }

  let cubes = useMemo(() =>
    sorted.reduce((arr, cubeTest) =>
      [...arr, ...cubeTest.cubes.filter(cube =>
        cube.numberOfDays && cube.numberOfDays === 28 && cube.pressureStrength && cube.pressureStrength > 0).map(cube =>
           ({ ...cube, cubeNumber: cubeTest.cubeNumber }))],
      [] as Array<Cube & { cubeNumber: number }>),[sorted])

  let cubes35=cubes.slice(0, 35)
  let stdDev35=cubes35.length===35 ? math.std(cubes35.map(cube => cube.pressureStrength || 0)) : 0
  let last35Date = new Date(cubes35[cubes35.length - 1].testDate)
  let displayChunks: Array<{ properties : CubesChunckProperties, cubes: Array<Cube & { cubeNumber: number }>}> = [];

  processCubes(cubes)

  function processCubes(input: Array<Cube & { cubeNumber: number }>){
    let chunks: Array<{ properties : CubesChunckProperties, cubes: Array<Cube & { cubeNumber: number }>}> = [];

    if (input.length < 35){
      if (input.length <= 15){
        chunks.push({
          properties : getChunckProperties(input, stdDev35, true),
          cubes : input
        })
      } else if (input.length <= 30){
        chunks.push({
          properties : getChunckProperties(input.slice(0,15), stdDev35, true),
          cubes : input.slice(0,15)
        })
        chunks.push({
          properties : getChunckProperties(input.slice(15, input.length), stdDev35, true),
          cubes : input.slice(15, input.length)
        })
      } else {
        chunks.push({
          properties : getChunckProperties(input.slice(0,15), stdDev35, true),
          cubes : input.slice(0,15)
        })
        chunks.push({
          properties : getChunckProperties(input.slice(15,30), stdDev35, true),
          cubes : input.slice(15,30)
        })
        chunks.push({
          properties : getChunckProperties(input.slice(30, input.length), stdDev35, true),
          cubes : input.slice(30, input.length)
        })
      }
    } else {
      chunks.push({
        properties : getChunckProperties(input.slice(0,15), stdDev35, true),
        cubes : input.slice(0,15)
      })
      chunks.push({
        properties : getChunckProperties(input.slice(15,30), stdDev35, true),
        cubes : input.slice(15,30)
      })
      chunks.push({
        properties : getChunckProperties(input.slice(30, 35), stdDev35, true),
        cubes : input.slice(30, 35)
      })
    }

    //for easy of access
    let mostRecentDev35 = stdDev35
    for (let i = 35; i < input.length; i+=15){
      //grab the next 15
      let nextChunk = input.slice(i, i + 15)
      let next = {
        properties : getChunckProperties(nextChunk, mostRecentDev35),
        cubes : nextChunk
      }


      let checkDate = new Date(last35Date)
      checkDate.setMonth(checkDate.getMonth() + 6)
      //check the min/max of the 15
      //or if the last s35 calculation was more than 6 months ago
      if (next.cubes.length === 15 &&
        ((next.properties.stdDev > 0 &&
          (next.properties.stdDev < next.properties.stdDevMin ||
          next.properties.stdDev > next.properties.stdDevMax)) ||
          new Date(next.cubes[next.cubes.length -1].testDate) > checkDate)){
        /*
        console.log('s35 triggered')
        console.log(next)
        console.log(next.properties.stdDev + " < " + next.properties.stdDevMin)
        console.log(next.properties.stdDev + " > " + next.properties.stdDevMax)
        console.log(new Date(next.cubes[next.cubes.length -1].testDate) + " > " + checkDate)*/

        //mark these 15 as part of 35
        next.properties.isCubes35 = true

        //mark previous chunk of 15 as part of 35
        chunks[chunks.length - 1].properties.isCubes35 = true

        //check if previous chunk had 15 or 5 cubes (there shouldn't be any other options)
        if (chunks[chunks.length - 1].cubes.length === 15){
          //if the second to last only has 5 cubes we don't need to do anything
          //if the last chunk had 15 cubes and the second to last as well we should grab 5 from the second to last and insert those as a new chunch between the 2
          if (chunks[chunks.length - 2].cubes.length === 15){
            let grab5 = chunks[chunks.length - 2].cubes.splice(10,15)
            //should insert this between last and second to last
            chunks.splice(chunks.length -1, 0,{
              properties : getChunckProperties(grab5, mostRecentDev35, true),
              cubes : grab5
            })
          }

        } else { //if not 15 it should be 5
          //if the last chunk had 5 cubes the second to last should have 15 which makes this easy
          chunks[chunks.length - 2].properties.isCubes35 = true
        }

        //recalculate the stdDev using THESE 15 and the previous 20 cubes (35 total)
        //at the end so the reason for the new calculation of 35 is traceable
        let newRange = input.slice(i-20, i+15)
        mostRecentDev35 = math.std(newRange.map(cube => cube.pressureStrength || 0))
        last35Date = new Date(newRange[newRange.length -1].testDate)
      }
      chunks.push(next)
    }

    //only show the chunks within the selected date range
    for (const chunk of chunks){
      if (new Date(chunk.properties.last.testDate) > startDate && new Date(chunk.properties.first.testDate) < endDate){
        displayChunks.push(chunk)

      }
    }
  }

  function getChunckProperties(chunk: any[], stdDev35: number, isCubes35: boolean=false): CubesChunckProperties {
    let values=chunk.map(cube => cube.pressureStrength || 0);
    let mean=math.mean(values);
    let stdDev=math.std(values);

    return {
      isCubes35: isCubes35,
      stdDev35: stdDev35,
      first: chunk[0],
      last: chunk[chunk.length - 1],
      values: values,
      mean: mean,
      min: math.min(values),
      max: math.max(values),
      stdDev: stdDev,
      fcm: stdDev ? mean - 1.48 * stdDev : 0,
      stdDevMin: stdDev35 ? stdDev35 * 0.63 : 0,
      stdDevMax: stdDev35 ? stdDev35 * 1.37 : 100,
      targetStrength: characteristicStrength - 4
    };
  }

  const maxLen = 15
  const { strengthClass, ingredients } = useMemo(() => family.receipts[0].revision.recipe, [family])
  const binders = useMemo(() => ingredients.filter(({ resource }) => resource.type === ResourceType.Cement || resource.type === ResourceType.Filler).map(({ resource }) => resource).sort((a, b) => a.id - b.id), [ingredients])
  const theme = useTheme()

  if(!displayChunks.length)
    return (
      <Fragment></Fragment>
    )

  return (
    <Table style={{ width: 'auto', marginBottom: 32 }} size="small">
      <TableHead>
        <TableRow>
          <TableCell colSpan={maxLen + 2} style={{ border: 0 }}>
            <Box display="flex">
              <Box>
                <Typography variant="body2">Sterkteklasse</Typography>
                <Typography>{strengthClass?.code}</Typography>
              </Box>
              <Box marginLeft={2}>
                <Typography variant="body2">Bindmiddelen</Typography>
                <Typography>{binders.map(b => b.name).join(', ')}</Typography>
              </Box>
              <Box marginLeft={2}>
                <Typography variant="body2">Familie</Typography>
                <Typography>{family.name}</Typography>
              </Box>
            </Box>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {displayChunks.map((chunk, i) => {
          return <Fragment key={i}>
            <TableRow>
              <TableCell style={{ border: 0, backgroundColor: chunk.properties.isCubes35 ? '#666' : '000'}}>{moment(chunk.properties.first.testDate).format('D-M-YYYY')}</TableCell>
              {chunk.cubes.map(cube => <TableCell key={new Date().getTime()+'-'+Math.random()} align="center" style={{ border: 0, backgroundColor: chunk.properties.isCubes35 ? '#666' : '000'}}>
                <Typography variant="caption">{moment(cube.testDate).format('D-M')}</Typography>
                <Typography variant="h6" style={{ border: 0, backgroundColor: chunk.properties.targetStrength > (cube.pressureStrength || 100) ? 'red' : '000'}}>{cube.pressureStrength}</Typography>
                <Typography variant="body2"><Link to={`/sample/${cube.cubeNumber}`} style={{ color: theme.palette.secondary.light }}>{cube.cubeNumber}</Link></Typography>
              </TableCell>)}
              <TableCell style={{ border: 0, backgroundColor: chunk.properties.isCubes35 ? '#666' : '000' }}>{moment(chunk.properties.last.testDate).format('D-M-YYYY')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ border: 0 }} />
              <TableCell colSpan={maxLen + 1} style={{ border: 0 }}>
                <Box display="flex" >
                  <span>min: {chunk.properties.min}</span>
                  <span style={{ marginLeft: 16 }}>max: {chunk.properties.max}</span>
                  {chunk.cubes.length === 15 && <span style={{ marginLeft: 16 }}>x15: <NumberFormat value={Math.round(chunk.properties.mean * 10) / 10} {...numberFormatProps} decimalScale={1} /></span>}
                  {chunk.cubes.length === 15 && chunk.properties.fcm && <span style={{ marginLeft: 16 }}>f'cm: <NumberFormat value={Math.round(chunk.properties.fcm * 10) / 10} {...numberFormatProps} decimalScale={1} /></span>}
                  {chunk.cubes.length === 15 && <span style={{ marginLeft: 16, backgroundColor: ((chunk.properties.stdDev < chunk.properties.stdDevMin || chunk.properties.stdDev > chunk.properties.stdDevMax) && i > 2)? 'orange' : '000' }}>s15: <NumberFormat value={Math.round(chunk.properties.stdDev * 10) / 10} {...numberFormatProps} decimalScale={1} /></span>}
                  {chunk.cubes.length === 15 && chunk.properties.stdDev35 > 0 && <span style={{ marginLeft: 16 }}>s35: <NumberFormat value={Math.round(chunk.properties.stdDev35 * 10) / 10} {...numberFormatProps} decimalScale={1} /></span>}
                  {chunk.cubes.length === 15 && chunk.properties.stdDevMin > 0 && chunk.properties.stdDevMax > 0 && <span style={{ marginLeft: 16 }}><NumberFormat value={Math.round(chunk.properties.stdDevMin * 10) / 10} {...numberFormatProps} decimalScale={1} /> ... <NumberFormat value={Math.round(chunk.properties.stdDevMax * 10) / 10} {...numberFormatProps} decimalScale={1} /></span>}
                </Box>
              </TableCell>
            </TableRow>
          </Fragment>
        })}
      </TableBody>
    </Table>)
}

export default ReportPressureStrength
